(function(window, document, $, __tnt) {

    var me = __tnt.eedition || (__tnt.eedition = {});

    var initResize = false;
    var firstResize = true;
    var lastMode;
    var lastOrientation = (window.matchMedia('(orientation: landscape)').matches || $(window).width() > $(window).height()) ? 'landscape' : 'portrait';

    me.mode = function(mode) {
        var mapContainer = $('#eedition-map-container'),
            segments = $('#eedition-segments'),
            segmentsContainer = $('#eedition-segments-container'),
            segmentsObj = [segmentsContainer, segments],
            segmentsMapObj = [mapContainer, segmentsContainer, segments],
            textSize = $('.eedition-text-size');

        switch(mode) {
            case 'image':
                me.subscription.tieredAccess($('#eedition-data').data('eedition-uuid'));
                segmentsContainer.css('visibility','visible').show();
                me.iscroll.preInit();
                textSize.hide();
                $('.eedition-view-option.eedition-view-image').parent().addClass('active-view');
                $('#eedition-sticky-container-text').hide();
                break;
            case 'text':
                me.iscroll.preInit();
                if (segments.length > 0) {
                    $('.eedition-zoom').hide();
                    $('#eedition-sticky-container-page').hide();
                    $.each(segmentsObj, function(i, el) {
                        $(el).show();
                    });
                    if (window.matchMedia('(orientation: landscape)').matches || $(window).width() > $(window).height()) {
                        $('#eedition-image').load(function() {
                            $(mapContainer).show();
                            $.when(me.textsize(Cookies.get('__tnt_eedition_textsize'))).done(function() {
                                $.each(segmentsMapObj, function(i, el) {
                                    $(el).css('visibility','visible').hide().fadeIn(500);
                                });
                            });
                        });
                    } else {
                        $.when(me.textsize(Cookies.get('__tnt_eedition_textsize'))).done(function() {
                            $.each(segmentsObj, function(i, el) {
                                $(el).css('visibility','visible').hide().fadeIn(500);
                            });
                        });
                    }
                    $('.eedition-view-option.eedition-view-text').parent().addClass('active-view');
                } else {
                    segmentsContainer.css('visibility','visible').show();
                    me.iscroll.preInit();
                    textSize.hide();
                    $('.eedition-view-option.eedition-view-image').parent().addClass('active-view');
                }
                break;
        }
    };

    me.resize = function() {

        var availableHeight = me.utilities.availableHeight(),
            bottombarHeight = $('.eedition-bottombar-main').outerHeight(),
            viewMode = me.utilities.viewMode($('#eedition-data').data('eedition-uuid'));

        if (lastMode != undefined && lastMode != viewMode && document.activeElement.tagName != 'INPUT') {
            var uuid = $('#eedition-data').data('eedition-uuid');
            window.location.href = $('#eedition-data').data('eedition')[uuid].url;
        }

        lastMode = viewMode;

        if (!$('#eedition-segments-container').hasClass(viewMode)) {
            $('#eedition-segments-container').addClass(viewMode);
        }

        if (viewMode == 'text') {
            $('#eedition-map-container').css('visibility','visible').show();
        }

        if (viewMode == 'text' && initResize) {
            if (window.matchMedia('(orientation: landscape)').matches || $(window).width() > $(window).height()) {
                if (lastOrientation !== 'landscape' && document.activeElement.tagName != 'INPUT') {
                    var uuid = $('#eedition-data').data('eedition-uuid');
                    window.location.href = $('#eedition-data').data('eedition')[uuid].url;
                } else {
                    $('.eedition-text-size').show();
                    $('#eedition-clip').hide();
                    $('.eedition-mainmenu-dropdown').show();
                    $('#eedition-map-container').addClass('no-zoom');
                }
            } else if (window.matchMedia('(orientation: portrait)').matches || $(window).width() < $(window).height()) {
                if (lastOrientation !== 'portrait' && document.activeElement.tagName != 'INPUT') {
                    var uuid = $('#eedition-data').data('eedition-uuid');
                    window.location.href = $('#eedition-data').data('eedition')[uuid].url;
                } else {
                    $('.eedition-text-size').hide();
                    $('#eedition-clip').show();
                    $('.eedition-mainmenu-dropdown').hide();
                    $('#eedition-map-container').removeClass('no-zoom');
                }
            }
        }

        if (viewMode == 'image') {
            if (!me.browser.isIos()) {
                if (
                    ($('#eedition-wrapper').hasClass('page-sizing-height') && firstResize) ||
                    ($('.image-plain').height() != 0 && ($('.image-plain').height() < me.utilities.availableHeight() || $('#eedition-data').data('zoomed-out')))
                ) {
                    $('.image-plain').css({height:me.utilities.availableHeight()+'px', width:'auto'});
                }

                if ($('.image-plain').width() > me.utilities.availableWidth()) {
                    $('.image-plain').css({width:'100%', height:'auto'});
                }
            }
        }

        if ($('#eedition-page-container').hasClass('mode-map')) {
            $('#eedition-map-container').css('height', availableHeight);
            if (me.iscroll && me.iscroll.map) {
                me.iscroll.map.refresh();
            }
        } else {
            $('#eedition-segments-container').css('height', availableHeight);
            if (me.iscroll && me.iscroll.segments) {
                me.iscroll.segments.refresh();
            }
        }

        $('.eedition-bottombar').css('bottom', -Math.abs(bottombarHeight));
        $('.eedition-bottombar').css('visibility', 'visible');

        if ($('.eedition-section-scroller').length > 0) {
            $('.eedition-section-scroller').width($('.eedition-section-scroller ul')[0].scrollWidth);
            if (me.iscroll.sections) {
                me.iscroll.sections.refresh();
            }
        }
        if ($('.eedition-thumbnail-scroller').length > 0) {
            $('.eedition-thumbnail-scroller').width($('.eedition-thumbnail-scroller ul')[0].scrollWidth);
            if (me.iscroll.pages) {
                me.iscroll.pages.refresh();
            }
        }

        if (me.browser.isIos() && !me.utilities.hasSegments() && me.utilities.isZoom()) {
            if (me.browser.isIpad()) {
                $('.eedition-scroller').width(2 * me.utilities.availableWidth()).height('');
            } else if (me.browser.isIphone() || me.browser.isIpod()) {
                $('.eedition-scroller').width(4 * me.utilities.availableWidth()).height('');
            }
        }

        // Move pages button up above eedition-page-bottom div if it has a height
        me.utilities.positionBottombarButton();

        if (!initResize) {
            initResize = true;
        }

        /* Navigation arrows */
        var navCookie = Cookies.get('__tnt_eedition_nav_arrows');
        if (navCookie == 0) {
            $('.eedition-settings-switch input').attr('checked', false);
        } else {
            $('.eedition-arrow').show();
        }

        /* Let's go ahead and check if there's a text size cookie present.
           If it's not set, we'll set it to default which is 1.*/
        var textCookie = Cookies.get('__tnt_eedition_textsize');
        if (textCookie != null) {
            Cookies.set('__tnt_eedition_textsize', textCookie, { expires: 365, path: '/' });
        } else {
            Cookies.set('__tnt_eedition_textsize', 1, { expires: 365, path: '/' });
        }

        firstResize = false;
    };

    me.textsize =  function(size) {

        /* Set the cookie, and then call flowtype() passing 'size' */
        Cookies.set('__tnt_eedition_textsize', size, { expires: 365, path: '/' });
        me.flowtype(size);
    };
})(window, document, jQuery, __tnt);